
import React, { Component } from 'react'
import logo from './logo.svg';
import './App.css';
import Preloader from './components/Preloader'
import Landing from './components/Landing'


class App extends Component {
  state = {
    isReady: false,
  }
  componentDidMount() {
    this.setState({isReady:true})
  }
  
  render() {
    return (
      <>
      {!this.state.isReady ?
      <div className="App">
        <header className="App-header">
          <Preloader/>
          
        </header>
        
      </div>
      
      :
      <div>
        <div class="content-bg-wrap"></div>
        <Landing/>
      </div>
      }
      </>
    )

  }
}


export default App;
